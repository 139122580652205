import { Button, Flex, Form, Input, Select, Spin } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";
import { useQuery } from "react-query";
import { useState } from "react";
import {
  getDeliveryPrice,
  getDeliveryZones,
  getGoogleMapsSuggestions,
  getPlaceDetails,
} from "../../../service";

const { Option } = Select;
export const GoogleMaps = () => {
  const { selectedBranch } = useAppContext();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [selivaryData, setDelivaryData] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [help, setHelp] = useState<any>();
  const [placeholder, setPlaceholder] = useState<any>("");

  const storegePlaceId = localStorage.getItem("place_id");
  const [storageMode, setStorageMode] = useState<any>(!!storegePlaceId);

  const { data: zones = [] } = useQuery(
    ["/delivery-zone"],
    () =>
      getDeliveryZones({
        id: selectedBranch?.id,
      }),
    {
      enabled: !!selectedBranch?.id,
    }
  );

  const { data: placeDetailsData } = useQuery(
    ["/place-details", storegePlaceId],
    () =>
      getPlaceDetails({
        place_id: storegePlaceId,
      }),
    {
      onSuccess(data) {
        let isInside = [];
        for (const zone of zones) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              new window.google.maps.LatLng(data.geometry),
              new window.google.maps.Polygon({ paths: zone?.zones })
            )
          ) {
            isInside.push(zone);
          }
        }
        const cheapestZone =
          isInside.length > 0 &&
          isInside.reduce((prev, curr) => {
            return curr.price < prev.price ? curr : prev;
          });

        if (cheapestZone) {
          setPlaceholder(data.name);
          setDelivaryData(cheapestZone);
          setStatus("success");
          setHelp(
            <>
              <p style={{ margin: 0 }}>
                Koszt dostawy: {(cheapestZone?.price / 100).toFixed(2)} zł
              </p>
              <p style={{ margin: 0 }}>
                Darmowa dostawia przy zamówieniu za min:{" "}
                {(cheapestZone?.freeAbove / 100).toFixed(2)} zł
              </p>
            </>
          );
        } else {
          setDelivaryData(null);
          setStatus("error");
          setHelp(
            <>
              <p style={{ margin: 0 }}>Nie obsługujemy dostaw w tym regionie</p>
            </>
          );
        }
      },
      enabled: !!storegePlaceId,
    }
  );

  const { isFetching, isLoading } = useQuery(
    ["/google-maps-suggestions", search],
    () => getGoogleMapsSuggestions({ search }),
    {
      onSuccess(data) {
        if (data.status === "OK" && data?.predictions?.length > 0) {
          setOptions(
            data?.predictions
              .filter(
                (e: any) =>
                  e?.types?.includes("premise") ||
                  e?.types?.includes("street_address")
              )
              .map((e: any) => {
                return {
                  value: e.place_id,
                  label: e.description,
                };
              })
          );
        }
      },
      enabled: search?.length > 5,
    }
  );

  useQuery(
    ["/delivery-price", selectOptions],
    () =>
      getDeliveryPrice({
        place_id: selectOptions,
      }),
    {
      onSuccess(data) {
        let isInside = [];
        for (const zone of zones) {
          if (
            window.google.maps.geometry.poly.containsLocation(
              new window.google.maps.LatLng(data),
              new window.google.maps.Polygon({ paths: zone?.zones })
            )
          ) {
            isInside.push(zone);
          }
        }
        const cheapestZone =
          isInside.length > 0 &&
          isInside.reduce((prev, curr) => {
            return curr.price < prev.price ? curr : prev;
          });

        if (cheapestZone) {
          setDelivaryData(cheapestZone);
          setStatus("success");
          setHelp(
            <>
              <p style={{ margin: 0 }}>
                Koszt dostawy: {(cheapestZone?.price / 100).toFixed(2)} zł
              </p>
              <p style={{ margin: 0 }}>
                Darmowa dostawia przy zamówieniu za min:{" "}
                {(cheapestZone?.freeAbove / 100).toFixed(2)} zł
              </p>
            </>
          );
        } else {
          setDelivaryData(null);
          setStatus("error");
          setHelp(
            <>
              <p style={{ margin: 0 }}>Nie obsługujemy dostaw w tym regionie</p>
            </>
          );
        }
      },
      enabled: !!selectOptions && !!selectedBranch?.id,
    }
  );

  const [typingTimeout, setTypingTimeout] = useState<number | null>(null); // Przechowuje timeout
  const handleSearch = (query: string) => {
    setStorageMode(false)
    setDelivaryData(null)
    setPlaceholder("");
    setStatus(null);
    setHelp(null);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (query && query.length > 5) {
      setTypingTimeout(
        window.setTimeout(() => {
          setSearch(query);
          setLoading(false);
        }, 1200)
      );
    } else {
      setOptions([]);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item
        label={
          storageMode
            ? "Ostatni adress dostawy"
            : "Miejscowość, ulica i numer domu (wpisz i wybierz adres z listy)"
        }
        rules={[{ required: true }]}
        validateStatus={status}
        help={help}
      >
          <Select
            loading={loading || isFetching || isLoading}
            allowClear
            style={{
              height: "40px",
            }}
            onSearch={(e) => {
              setOptions([]);
              if (e?.length > 5) setLoading(true);
              handleSearch(e);
            }}
            showSearch
            
            placeholder={placeholder}
            labelInValue
            filterOption={false}
            onChange={(e) => e?.value && setSelectOptions(e.value)}
            notFoundContent={
              loading || isFetching || isLoading ? (
                <Flex
                  justify="center"
                  style={{
                    padding: 20,
                  }}
                >
                  <Spin />
                </Flex>
              ) : null
            }
          >
            {options.map((option: any) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>

     
      </Form.Item>
      <Form.Item label={"Numer mieszkania (opcjonalne)"} required={false}>
        <Input
          style={{
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          pattern="^\d+(\.\d{1,2})?$"
        />
      </Form.Item>
    </Form>
  );
};
