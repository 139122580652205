import React from "react";
import locale_pl from "dayjs/locale/pl";
import dayjs from "dayjs";
import styled from "styled-components";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";

dayjs.locale(locale_pl);

function generateDaysArray() {
  let currentDate = dayjs.utc();
  let daysArray = [];

  for (let i = 0; i < 90; i++) {
    let formattedDate = currentDate.format("YYYY-MM-DD");
    let formattedLabel = currentDate.format("DD MMM");
    let formattedDayName = i === 0 ? "Dziś" : currentDate.format("ddd"); // Polish day name
    daysArray.push({
      date: formattedDate,
      label: formattedLabel,
      dayName: formattedDayName,
    });

    currentDate = currentDate.add(1, "day");
  }

  return daysArray;
}

const DataWrapper = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-bottom: 20px;
`;

const Data = styled.div<{ $selected: boolean }>`
  padding: 16px;
  min-width: 100px;
  border: 1px solid ${({ $selected }) => ($selected ? "#4f46e5" : "#d1d5db")};
  height: 64px;
  border-radius: 7px;
  cursor: pointer;
`;

export const DataPickerCarusel: React.FC = () => {
  const dataArray = generateDaysArray();
  const { serviceData, setServiceData } = useAppContext();

  return (
    <DataWrapper>
      {dataArray.map((data) => {
        return (
          <Data
            $selected={serviceData === data.date}
            onClick={() => setServiceData(data.date)}
          >
            <P $size="14" $height="16" $weight="400">
              {data.dayName}
            </P>
            <P $size="12" $height="20" $weight="300" $family="Lato-Light">
              {data.label}
            </P>
          </Data>
        );
      })}
    </DataWrapper>
  );
};
