import { Flex } from "antd";
import "./index.css";
import { motion } from "framer-motion";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { GoogleMaps } from "../google-maps/GoogleMaps";

export const Toggle = () => {
  const { delivery, setDelivery } = useAppContext();

  const textVariants = {
    initial: { color: "#f3f4f6" }, // Initial color (e.g., black)
    animate: {
      color: "#4f46e5", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  const textVariants2 = {
    initial: { color: "#4f46e5" }, // Initial color (e.g., black)
    animate: {
      color: "#f3f4f6", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  return (
    <>
      <Flex justify="center">
        <div
          style={{
            position: "relative",
          }}
        >
          <label
            htmlFor="delivery-type"
            style={{
              position: "absolute",
              top: "15px",
              left: "73px",
              zIndex: 1000,
            }}
          >
            <motion.div
              initial="initial"
              animate={delivery === "SELF_PICKUP" ? "animate" : "initial"}
              variants={textVariants}
            >
              <P $size="11" $weight="400" $height="16">
                Dostawa
              </P>
            </motion.div>
          </label>
          <label
            htmlFor="delivery-type"
            style={{
              position: "absolute",
              top: "15px",
              right: "50px",
              zIndex: 1000,
            }}
          >
            <motion.div
              initial="initial"
              animate={delivery === "SELF_PICKUP" ? "animate" : "initial"}
              variants={textVariants2}
            >
              <P $size="11" $weight="400" $height="16">
                Odbiór osobisty
              </P>
            </motion.div>
          </label>
          <input
            type="checkbox"
            id="delivery-type"
            checked={delivery === "SELF_PICKUP"}
            onChange={(e) => {
              setDelivery(
                delivery === "SELF_PICKUP" ? "TO_THE_ADDRESS" : "SELF_PICKUP"
              );
            }}
          />
        </div>
      </Flex>
      {
        delivery ==='TO_THE_ADDRESS' && <GoogleMaps/>
      }
    </>
  );
};
