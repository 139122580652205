import { Button, Flex, notification } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";
import { useFormContext } from "react-hook-form";
import { saveiInStorage } from "../../../utils";
import { useMutation } from "react-query";
import {
  createPayments,
  createProductOrder,
  createServiceOrder,
  getCompanyPayments,
} from "../../../service";
import dayjs from "dayjs";

const GoToDeliveryButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #f3f4f6;
  display: flex;
  justify-content: end;
  background: white;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 48px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    background: #4f46e595 !important;
    border-color: #4f46e595 !important;
  }
`;

const AdditionPrice: React.FC<{ price: any }> = ({ price }) => {
  const converderdPrice = price / 100;
  return <>{`${converderdPrice.toFixed(2)} zł`}</>;
};

export const GoToPay: React.FC<{ selectedOptionObject: any }> = ({
  selectedOptionObject,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    cart,
    delivery,
    deliveryPrice,
    serviceData,
    serviceTime,
    selectedBranch,
    deliveryData,
  } = useAppContext();
  const total_ammount = useMemo(
    () =>
      cart
        .map(
          (position: any) =>
            position.cart_positoin.meal_price *
            position.cart_positoin.meal_quantity
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [cart]
  );

  const isOrder = !!location?.state?.isOrder;

  const {
    mutateAsync: mutateAsynccreatePayments,
    isLoading: createPaymentsLoading,
  } = useMutation(createPayments, {
    onSuccess: (response) => {
      if (response.status === 201) {
        if (
          !!response.data.id &&
          (response.data.status === "CONFIRMED" ||
            response.data.status === "PENDING")
        ) {
          if (response.data.content?.transactionData?.link) {
            window.open(response.data.content.transactionData?.link, "_blank");
            // window.location.replace(response.data.content.transactionData?.link);
            navigate("/thank-you", { state: { from: "home" } });
          } else {
            navigate("/thank-you", { state: { from: "home" } });
          }
        }
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading: createIsLoading } = useMutation(
    createServiceOrder,
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          mutateAsynccreatePayments({
            amount: total_ammount,
            content: {},
            paymentMethodId: selectedOptionObject?.value,
            serviceOrderId: response.data.id,
          });
        }
      },
      onError: () => {
        notification.error({
          message: "Błąd",
          style: {
            background: "#ff4d4f30",
          },
        });
      },
    }
  );

  const {
    mutateAsync: productOrderMutateAsync,
    isLoading: productOrderMutateLoading,
  } = useMutation(createProductOrder, {
    onSuccess: (response) => {
      if (response.status === 201) {
        mutateAsynccreatePayments({
          amount: total_ammount,
          content: {},
          paymentMethodId: selectedOptionObject?.value,
          serviceOrderId: response.data.id,
        });
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const submit = () => {
    if (isOrder) {
      productOrderMutateAsync({
        price: total_ammount,
        details: {
          ...deliveryData,
          cart,
          payments: selectedOptionObject?.label,
        },
        deliveryMethod: 1,
        status: "WAITING_FOR_PAYMENT",
        companyBranch: selectedBranch,
      });
    } else {
      mutateAsync({
        price: total_ammount,
        startTime:  dayjs.utc(`${serviceData} ${serviceTime}`, "YYYY-MM-DD HH:mm"),
        details: {
          ...deliveryData,
          cart,
          payments: selectedOptionObject?.label,
        },
        serviceId: cart[0].cart_positoin.id,
        companyBranchId: selectedBranch.id,
      });
    }
  };

  return (
    <GoToDeliveryButtonWrapper>
      <Flex
        vertical
        justify="space-between"
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <Flex
          justify="space-between"
          gap={20}
          style={{
            maxWidth: "450px",
            width: "100%",
          }}
        >
          <CostumAddButton
            onClick={submit}
            loading={
              createIsLoading ||
              createPaymentsLoading ||
              productOrderMutateLoading
            }
            disabled={!selectedOptionObject}
          >
            <P $size="16" $height="20" $weight="400">
              {isOrder ? (
                <>
                  Zamawiam (
                  {<AdditionPrice price={total_ammount + deliveryPrice} />})
                </>
              ) : (
                <>
                  Rezerwuję (
                  {<AdditionPrice price={total_ammount + deliveryPrice} />})
                </>
              )}
            </P>
          </CostumAddButton>
        </Flex>
      </Flex>
    </GoToDeliveryButtonWrapper>
  );
};
