import axios, { AxiosResponse } from "axios";

export const PATH = process.env.REACT_APP_API_URL;

export const getServices = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/services_api/services/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServicesCategory = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/services_api/service_categories`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetails = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `https://srv22208.microhost.com.pl/api/index.php?file=details`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBranches = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/company_branches`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAvailabilityOnDate = async ({
  id,
  dateToBeChecked,
}: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/services_api/service_availability/${id}/${dateToBeChecked}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDelivery = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/api/index.php?file=delivery`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSuggestions = async ({ input }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post<any>(
      `https://resmanager.srv22208.microhost.com.pl/get_suggestions.php`,
      {
        adress: input,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPlace = async ({ place_id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post<any>(
      `https://resmanager.srv22208.microhost.com.pl/get_place.php`,
      {
        place_id: place_id,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createServiceOrder = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post<any>(
      `${PATH}/v1/services_api/service_order`,
      {
        ...data,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createProductOrder = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post<any>(
      `${PATH}/v1/products_api/product_order`,
      {
        ...data,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPayments = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post<any>(
      `${PATH}/v1/common_api/payments`,
      {
        ...data,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCompanyPayments = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/payment_methods`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductCategories = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/products_api/client/categories`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProducts = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/products_api/products/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductDetails = async ({ id }: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/products_api/product/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGoogleMapsSuggestions = async ({
  search,
}: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/adress-seggestions/${search}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDeliveryPrice = async ({
  place_id,
}: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/delivery-price/${place_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getPlaceDetails = async ({
  place_id,
}: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/place-details/${place_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getDeliveryZones = async ({
  id,
}: {
  id: number | string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(
      `${PATH}/v1/common_api/delivery-zones/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
